import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import {getSalesTrainingPromptList} from "../../../networking/apiEndpoints";
import { SalesTrainingPromptFilterModel, SalesTrainingPromptResponseState } from "../../../types/components/rass/salesTrainingPromptModel";

const initialState: SalesTrainingPromptResponseState = {
    salesTrainingPromptResponseModel:{
    status:false,
    message:"",
    result: null,    
 } ,
  loading: false,
  error:null,  
  responseStatus: 200
};
export const fetchSalesTrainingPromptList = createAsyncThunk<
  any,
  SalesTrainingPromptFilterModel,
  {
    rejectValue: AxiosResponse;
  }
>(
  "SalesTrainingPromptlist/fetch", 
  async ( _SalesTrainingPromptFilterModelData:SalesTrainingPromptFilterModel, thunkApi) => {
    var token = localStorage.getItem('token');    
    const response = await axios.post(`${getSalesTrainingPromptList}`, _SalesTrainingPromptFilterModelData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },      
        validateStatus: function (status) {
          return status < 500;
        },
      });
      if (response.status === 401) {
        return thunkApi.rejectWithValue(response);
      }   
      const transformedData = response.data.result.map((data)=>({
        id: data.id,
        name: data.name,
        type: data?.type === "S" ? "Standard" : (data?.type === "P" ? "Custom" : "Unknown"),
        orderNo: data.orderNo,
        createdBy: data.createdBy,
        createdOn: data.createdOn,
        modifiedBy: data.modifiedBy,
        modifiedOn: data.modifiedOn,
        text: data.text,
      }));
      return transformedData;    
  }
);
export const getSalesTrainingPromptSlice = createSlice({
  name: "SalesTrainingPromptlist",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSalesTrainingPromptList.pending, (state) => {
      state.loading = true;
      state.error = '';
    })
    .addCase(fetchSalesTrainingPromptList.fulfilled, (state, action) => {    
      state.salesTrainingPromptResponseModel = action.payload;
      state.loading = false;
    }) 
    .addCase(fetchSalesTrainingPromptList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? '';
      if (action.payload !== undefined) {
        state.responseStatus = action.payload.status;
    }
    });   
  },
});

export default getSalesTrainingPromptSlice.reducer;
