const domain = process.env.REACT_APP_API_ENDPOINT;
const identitydomain = process.env.REACT_APP_IDENTITY_API_ENDPOINT;
const rrasdomain = process.env.REACT_APP_RRAS_API_ENDPOINT;
const secretKey = process.env.REACT_APP_LOGIN_SECRETKEY;

export const loginSecretKey = secretKey;
export const getPerson = `${domain}/persons`;
export const getContractDetails = `${domain}/api/contracts`;
export const getPreProcessingContractDetails = `${domain}/api/screening-contracts/get-pre-processing-contract-details`;
export const getContractList = `${domain}/api/contracts/filter-contracts`;
export const getContractFileStream = `${domain}/api/contract-attachments/get-file-stream`;
export const deleteContract = `${domain}/api/contracts`;
export const getContractTypes = `${domain}/api/contract-types/filter-contract-types`;
export const addEditContractTypes = `${domain}/api/contract-types`;
export const uploadNewContract = `${domain}/api/contract-attachments/upload-contract-files`;
export const uploadContractDetails = `${domain}/api/contracts`;
export const duplicateContractType = `${domain}/api/contract-types/check-duplicate-contract-types`;
export const getContractTypeById = `${domain}/api/contract-types`;
export const editContract = `${domain}/api/contracts`;
export const invokedsAlgorithm = `${domain}/api/contracts/call-ds-algorithm`;
export const addContractTerm = `${domain}/api/contract-terms`;
export const editContractTerm = `${domain}/api/contract-terms`;
export const duplicateCheckContractTerm = `${domain}/api/contract-terms/check-duplicate-contract-term`;
export const getContractTerms = `${domain}/api/contract-terms/get-contract-terms`;
export const deleteContractTerms = `${domain}/api/contract-terms/delete-contract-terms`;
export const getContractTermById = `${domain}/api/contract-terms`;
export const duplicateContractName = `${domain}/api/contracts/contract-name-exists`;
export const getContractListByTypeId = `${domain}/api/contracts/contract-list-by-contract-type-id`;
export const deleteContractType = `${domain}/api/contract-types`;
export const addFeedback = `${domain}/api/feedbacks`;
export const getFeedbackList = `${domain}/api/feedbacks`;
export const receiveNotificationContractProcessed = `${domain}/notificationHub`;
export const emailCheck = `${identitydomain}/api/user/is-email-exists`;
export const UsernameCheck = `${identitydomain}/api/user/is-username-exist`;
export const addNewUserApiEndpoint = `${identitydomain}/api/user/register`;
export const forgotPassword = `${identitydomain}/api/user/forgot-password`;
export const resetPassword = `${identitydomain}/api/user/reset-password`;
export const changePassword = `${identitydomain}/api/user/change-password`;
export const previousPwdValidation = `${identitydomain}/api/user/previous-pwd-validation`;
export const login = `${identitydomain}/api/user/login`;
export const refreshToken = `${identitydomain}/api/user/refresh-token`;
export const getAllRoles = `${identitydomain}/api/role/all-roles`;
export const getAllTenants = `${identitydomain}/api/tenant/all-tenants`;
export const getAllUsers = `${identitydomain}/api/user/all-users`;
export const createUser = `${identitydomain}/api/user/register`;
export const getUserclaims = `${identitydomain}/api/user/user-claims`;
export const getUsersByTenant = `${identitydomain}/api/user/users-by-tenent`;
export const getFilterUsers = `${identitydomain}/api/user/filter-users`;
export const getUsersWithoutPermissions = `${identitydomain}/api/user/get-users-by-tenant-id`;
export const updateUserStatus = `${identitydomain}/api/user/update-user-status`;
export const getUserDetails = `${identitydomain}/api/user/get-user-by-id`;
export const editUser = `${identitydomain}/api/user/edit-user`;
export const uploadUserImage = `${identitydomain}/api/user/upload-user-image`;
export const downloadUserImage = `${identitydomain}/api/user/download-user-image`;
export const deleteUserImage = `${identitydomain}/api/user/delete-user-image`;
export const getTenantNameById = `${identitydomain}/api/tenant/tenant-name-by-id`;
export const getContractReport = `${domain}/api/contracts/contracts-report`;
export const deleteMultiContract = `${domain}/api/contracts/delete-multiple-contracts`;
export const getAttachContract = `${domain}/api/contracts/get-child-contract`;
export const addChildContractEndPoint = `${domain}/api/contracts/add-child-contract`;
export const getContractDetailsAttachments = `${domain}/api/contract-attachments/get-contract-attachment-list`;
export const getAttachedContractTerms = `${domain}/api/contracts/get-terms-for-linked-contracts`;
export const deleteMultiChildContract = `${domain}/api/contracts/delete-child-contracts`;
export const changeChildContractPrecedence = `${domain}/api/contracts/set-child-contract-precedence`;
export const getPromptTemplates = `${domain}/api/contracts/get-prompt-templates`;
export const getLLMMethods = `${domain}/api/contract-terms/get-llm-methods`;
export const createRoleEndPoint = `${identitydomain}/api/role/create-role`;
export const editRoleEndPoint = `${identitydomain}/api/role/edit--role`;
export const savePermissions = `${identitydomain}/api/role/save-permissions`;
export const getRolesListEndPoint = `${identitydomain}/api/role/get-roles`;
export const getFilterRolesEndPoint = `${identitydomain}/api/role/filter-roles`;
export const getPermissions = `${identitydomain}/api/role/get-all-permissions`;
export const getPermissionsByRoleId = `${identitydomain}/api/role/get-role-permissions`;
export const getRolePermissionsEndPoint = `${identitydomain}/api/role/get-role-permissions`;
export const getAssignedUsersRoleEndPoint = `${identitydomain}/api/role/assigned-users-of-role`;
export const assignUsersEndPoint = `${identitydomain}/api/role/assign-users-to-roleid`;
export const deleteRole = `${identitydomain}/api/role/delete-role`;
export const updateUserDisclaimerLogs = `${identitydomain}/api/user/update-user-disclaimer-acceptance`;
export const getCloneRoles = `${identitydomain}/api/role/get-existing-roles-for-clone`;
export const getpreviousnextcontracttermbyid = `${domain}/api/contract-terms/get-previous-next-contract-term-by-id`;
export const getScreeningType = `${domain}/api/screening-terms/get-screening-types`;
export const getScreeningTerms = `${domain}/api/screening-terms/get-screening-terms`;
export const saveScreeningTerms = `${domain}/api/screening-terms/save-screening-terms`;
export const editScreeningTerms = `${domain}/api/screening-terms/get-screening-term`;
export const getScreeningContracts = `${domain}/api/screening-contracts/get-screening-contracts`;
export const deleteScreeningTerms = `${domain}/api/screening-terms/delete-screening-terms`;
export const moveContracts = `${domain}/api/screening-contracts/moved-screening-contracts`;
export const getScreeningContractsStatus = `${domain}/api/screening-contracts/get-screening-contracts-status`;
export const reanalyzeStatusUpdate = `${domain}/api/screening-contracts/reprocess-contracts`;
export const deleteScreeningContractsEndPoint = `${domain}/api/screening-contracts/delete-pre-processing-contracts`;

// RRAS end points
export const getEmailTemplateList = `${rrasdomain}/api/Template/get-email-template`;
export const getEmailCreatorList = `${rrasdomain}/api/Template/get-email-creator`;
export const getSummaryTemplateList = `${rrasdomain}/api/Template/get-summary-template`;
export const addFollowUpEmailEndPoint = `${rrasdomain}/api/Template/add-email-creator`;
export const getGeneratedEmailAndSummaryText = `${rrasdomain}/api/Template/get-generated-emailandsummary`;
export const getDepartmentList = `${rrasdomain}/api/Template/get-departments`;
export const getEmailCreatorVersionByEmailCreatorId = `${rrasdomain}/api/Template/get-emailcreator-versions-by-emailcreator-id`;
export const addEmailTemplateEndPoint = `${rrasdomain}/api/Template/upsert-email-template`;
export const deleteEmailFollowup = `${rrasdomain}/api/Template/delete-email-creator`;
export const deleteEmailTemplate = `${rrasdomain}/api/Template/delete-email-template`;
export const addEditSummaryTemplates = `${rrasdomain}/api/Template/upsert-summary-template`;
export const getGeneratedEmailText = `${rrasdomain}/api/Template/get-generated-emailtext`;
export const deleteSummaryTemplate = `${rrasdomain}/api/Template/delete-summary-template`;
export const addUserDisclaimerLogs = `${rrasdomain}/api/Template/add-user-disclaimer-acceptance`;

export const addEditCompaniesEndPoint = `${rrasdomain}/api/Seagage/upsert-companies`;
export const addEditCompanyDetailsEndPoint = `${rrasdomain}/api/Seagage/upsert-company-details`;
export const getSeagageLastSyncInfoEndPoint = `${rrasdomain}/api/Seagage/get-seagage-lastsync-info`;
export const getModuleListSeagage = `${rrasdomain}/api/Seagage/get-module-list-seagage`;
export const getPersonaTitleList = `${rrasdomain}/api/Seagage/get-persona-title-values`;
export const getPersonaData = `${rrasdomain}/api/Seagage/get-persona-details-by-title`;

export const getGeneratedMessageAPI = `${rrasdomain}/api/Message/get-generated-message`;
export const upsertGeneratedMessage = `${rrasdomain}/api/Message/upsert-message-creator`;
export const getMessageCreatorAPI = `${rrasdomain}/api/Message/get-message-creator`;
export const deleteMessageAPI = `${rrasdomain}/api/Message/delete-message`;

export const getSalesTrainingPromptList = `${rrasdomain}/api/SalesTrainingPrompt/filter-training-prompts`;
export const addSalesTrainingPromptEndPoint = `${rrasdomain}/api/SalesTrainingPrompt/save-sales-training-prompt`;