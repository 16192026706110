import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import contractTypesSlice from "./features/contractTypeSlice";
import uploadNewContractSlice from "./features/AddContractFileSlice";
import contractDetailsSlice from "./features/contractDetailsSlice";
import contractListSlice from "./features/contractListSlice";
import selectTemplateSlice from "./features/selectTemplateSlice";
import AddContractDetailsSlice from "./features/AddContractDetailsSlice";
import getContractFileSlice from "./features/getContractFileSlice";
import contractDeleteSlice from "./features/contractDeleteSlice";
import addEditContractTypeSlice from "./features/addEditContractTypeSlice";
import getContractTypeIdSlice from "./features/getContractTypeByIdSlice";
import EditContractSlice from "./features/EditContractSlice";
import addEditContractTermSlice from "./features/addEditContractTermSlice.";
import contractTermSlice from "./features/contractTermSlice";
import getContractTermByIdSlice from "./features/getContractTermByIdSlice";
import deleteContractTermSlice from "./features/DeleteContractTermSlice";
import contractListByTypeIdSlice from "./features/contractListByTypeIdSlice";
import contractTypeDeleteSlice from "./features/contractTypeDeleteSlice";
import getMenuSlice from "./features/getMenuSlice";
import feedbackListSlice from "./features/feedbackListSlice";
import ContractTypeFilterListSlice from "./features/contractTypeFilterSlice";
import isEmailExistsSlice from "./features/isEmailExistsSlice";
import loginSlice from "./features/loginSlice";
import forgotPasswordAsynSlice from "./features/forgotPassword";
import passwordResetAsynSlice from "./features/passwordReset";
import getRolesSlice from "./features/getRolesSlice";
import getAllTenantSlice from "./features/getAllTenantSlice";
import createUserSlice from "./features/createUserSlice";
import userListSlice from "./features/listUserSlice";
import userClaimSlice from "./features/userClaimsSlice";
import getUsersByTenantSlice from "./features/getUsersByTenantSlice";
import getTenantNameById from "./features/getTenantNameById";
import contractIdSlice from "./features/contractIdSlice";
import contractReportSlice from "./features/contractReportSlice";
import changePasswordAsynSlice from "./features/changePassword";
import attachContractListSlice from "./features/attachContractListSlice";
import getContractDetailsAttachmentsSlice from "./features/getContractDetailsAttachments";
import termListForContractSlice from "./features/termListForContractSlice";
import deleteAttachContractSlice from "./features/DeleteAttachContractSlice";
import getLLMMethodsSlice from "./features/getLLMMethodsSlice";
import getEmailCreatorSlice from "./features/rras/getEmailCreatorSlice";
import getSummaryTemplateSlice from "./features/rras/getSummaryTemplateSlice";
import getEmailTemplateListSlice from "./features/rras/getEmailTemplateListSlice";
import addFollowUpEmailCreatorSlice from "./features/addFollowUpEmailCreator";
import getEmailSummaryGeneratedTextSlice from "./features/rras/getGeneratedEmailSummaryTextSlice";
import getDepartmentListSlice from "./features/rras/getDepartmentSlice";
import getEmailCreatorVersionByEmail from "./features/rras/getEmailCreatorVersionByEmailCreatorIdSlice";
import addEditSummaryTemplateSlice from "./features/rras/addEditSummaryTemplateSlice";
import flashMessageSlice from "./flashMessage/snackFlashMessageSlice";
import addEmailTemplateSlice, { addEmailTemplate } from "./features/addEmailTemplateSlice";
import deleteEmailFollowupSlice from "./features/rras/deleteEmailFollowupSlice";
import moduleSwitcherSlice from "./features/moduleSwitcher/moduleSwitcherTabSlice";
import addRoleSlice from "./features/addRoleSlice";
import editRoleSlice from "./features/editRoleSlice";
import getRolesListSlice from "./features/getRolesListSlice";
import savePermissionSlice from "./features/savePermissionSlice";
import getAllPermissionSlice from "./features/getAllPermissionSlice";
import getRolePermissionSlice from "./features/getRolePermissionSlice";
import getGeneratedEmailTextSlice from "./features/rras/getGeneratedEmailTextSlice";
import getAssignedUsersRoleSlice from "./features/getAssignedUsersRoleSlice";
import getPermissionsByRoleIdSlice from "./features/getPermissionsByRoleIdSlice";
import assignUserReducer from './features/assignUsersSlice'; 
import assignUsersSlice from "./features/assignUsersSlice";
import regModuleMessageSlice from "./features/rras/regModuleMessage/regModuleMessageSlice";
import currentPortcoSlice from "./UserSlice/setPotcoSlice";
import currentAuthSlice from "./UserSlice/setAuthSlice";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import deleteSummaryTemplateSlice from "./features/rras/deleteSummaryTemplateSlice";
import deleteEmailTemplateSlice from "./features/rras/deleteEmailTemplateSlice";
import deleteRoleSlice from "./features/deleteRoleSlice";
import userInformationSlice from "./UserSlice/setUserInfoSlice";
import profileImageSlice from "./UserSlice/setDownloadImageSlice";
import  updateUserDisclaimerLogsSlice from "./features/rras/updateUserDisclaimerLogsSlice";
import addUserDisclaimerLogsSlice from "./features/rras/addUserDisclaimerLogsSlice";
import getRolesForCloneSlice from "./features/getRolesForCloneSlice";
import getNextPreviousContractTermIdsSlice from "./features/getNextPreviousContractTermIdsSlice";
import refreshTokenSlice from "./features/refreshTokenSlice";
import getScreeningTypeSlice from "./features/getScreeningTypeSlice";
import getScreeningTermsByTenantIdSlice from "./features/getScreeningTermsByTenantIdSlice";
import saveScreeningTermSlice from "./features/saveScreeningTermSlice";
import editScreeningTermSlice from "./features/editScreeningTermSlice";
import addEditCompanyDetailsSlice from "./features/rras/addEditCompanyDetailsSlice";
import  addEditCompaniesSlice from "./features/rras/addEditCompaniesSlice";
import preProcessingContractDetailsSlice from "./features/preProcessingContractDetailsSlice";
import getSeagageLastSyncInfoSlice from "./features/rras/getSeagageLastSyncInfoSlice";
import getPreProcessingContractsListSlice from "./features/getPreProcessingContractsListSlice";
import getModuleListSlice from "./features/rras/regModuleMessage/getModuleListSlice";
import getPersonaTitleListSlice from "./features/rras/regModuleMessage/getPersonaTitleListSlice";
import getPersonaDataSlice  from "./features/rras/regModuleMessage/getPersonaDataSlice";
import  getGeneratedMessageDataSlice  from "./features/rras/regModuleMessage/getGeneratedMessageSlice";
import movePreProcessedContractsSlice from "./features/moveContractsSlice";
import deleteScreeningTermSlice from "./features/screeningTermDeleteSlice";
import upsertGeneratedMessageSlice  from "./features/rras/regModuleMessage/upsertGeneratedMessageSlice";
import screeningContractsStatusListSlice from "./features/screeningContractsStatusSlice";
import getMessageCreatorSlice from "./features/rras/regModuleMessage/getMessageCreatorSlice";
import reanalyzeScreeningContractSlice from "./features/reanalyzeScreeningContractsSlice";
import isUsernameExistSlice from "./features/isUsernameExistSlice";
import deleteMessageSlice from "./features/rras/regModuleMessage/deleteMessageSlice";
import deleteScreeningContractsSlice from "./features/screeningContractsDeleteSlice";
import getUsersWithoutPermissionsSlice from "./features/getUsersWithoutPermissionsSlice";
import getSalesTrainingPromptSlice from "./features/rras/getSalesTrainingPromptSlice";
import addSalesTrainingPromptSlice from "./features/addSalesTrainingPromptSlice";

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['regModuleMessage', 'currentPortco', 'getAllTenant', 'getMenu', 'moduleSwitcher','userInformation'],
};
const rootReducer = combineReducers({
  contractdetails: contractDetailsSlice,
  contractlist: contractListSlice,
  contractTypes: contractTypesSlice,
  selectTemplateList: selectTemplateSlice,
  uploadnewcontract: uploadNewContractSlice,
  addContractDetails: AddContractDetailsSlice,
  getFilebystream: getContractFileSlice,
  addEditContractTypes: addEditContractTypeSlice,
  addEditSummaryTemplates: addEditSummaryTemplateSlice,
  deleteContract: contractDeleteSlice,
  getContractTypeId: getContractTypeIdSlice,
  editContract: EditContractSlice,
  addUpdateContractTerm: addEditContractTermSlice,
  contractTerms: contractTermSlice,
  getContractTerm: getContractTermByIdSlice,
  deleteContractTerm: deleteContractTermSlice,
  getContractListByTypeId: contractListByTypeIdSlice,
  deleteContractType: contractTypeDeleteSlice,
  getMenu: getMenuSlice,
  getFeedback: feedbackListSlice,
  ContractTypeFilter: ContractTypeFilterListSlice,
  isEmailExists: isEmailExistsSlice,
  login: loginSlice,
  refreshToken: refreshTokenSlice,
  forgotPassword: forgotPasswordAsynSlice,
  resetPasswordstore: passwordResetAsynSlice,
  getRoles: getRolesSlice,
  getAllTenant: getAllTenantSlice,
  createUser: createUserSlice,
  getAllUser: userListSlice,
  getUserClaims: userClaimSlice,
  getUsersByTenant: getUsersByTenantSlice,
  getTenantName: getTenantNameById,
  setContractId: contractIdSlice,
  getContractReport: contractReportSlice,
  getAttachContract: attachContractListSlice,
  changePassword: changePasswordAsynSlice,
  getContractDetailsAttachments: getContractDetailsAttachmentsSlice,
  getTermsForAttachedContracts: termListForContractSlice,
  deleteChildContracts: deleteAttachContractSlice,
  getLLMMethods: getLLMMethodsSlice,
  getEmailCreatorList: getEmailCreatorSlice,
  getEmailCreatorVersionByEmailList: getEmailCreatorVersionByEmail,
  getSummaryTemplateList: getSummaryTemplateSlice,
  getEmailTemplateList: getEmailTemplateListSlice,
  saveFollowUpEmail: addFollowUpEmailCreatorSlice,
  getEmailSummaryGeneratedText: getEmailSummaryGeneratedTextSlice,
  getDepartmentList: getDepartmentListSlice,
  flashMessage : flashMessageSlice,
  addEmailTemplate: addEmailTemplateSlice,
  deleteEmailFollowUp : deleteEmailFollowupSlice,
  deleteEmailTemplate : deleteEmailTemplateSlice,
  moduleSwitcher : moduleSwitcherSlice,
  addRole : addRoleSlice,
  editRole : editRoleSlice, 
  savePermission: savePermissionSlice,
  getRolesList: getRolesListSlice,
  getAllPermission: getAllPermissionSlice,
  getEmailTextGeneratedSlice: getGeneratedEmailTextSlice,
  getRolePermission: getRolePermissionSlice,
  getPermissionsByRoleId: getPermissionsByRoleIdSlice,
  getAssignedUsersRole: getAssignedUsersRoleSlice,
  assignUserList: assignUserReducer,
  assignUsers: assignUsersSlice,
  regModuleMessage : regModuleMessageSlice, 
  currentPortco : currentPortcoSlice,
  currentAuth : currentAuthSlice,
  deleteSummaryTemplate : deleteSummaryTemplateSlice,
  deleteRole: deleteRoleSlice,
  userInformation: userInformationSlice,
  profileImage: profileImageSlice,  
  updateUserDisclaimerLogs: updateUserDisclaimerLogsSlice,
  addUserDisclaimerLogs: addUserDisclaimerLogsSlice,
  getRolesForClone: getRolesForCloneSlice,
  getNextPreviousContractTermIds: getNextPreviousContractTermIdsSlice,
  getScreeningType: getScreeningTypeSlice,
  getScreeningTermsByTenantId: getScreeningTermsByTenantIdSlice,
  saveScreeningTerm: saveScreeningTermSlice,
  editScreeningTerm: editScreeningTermSlice,
  addEditCompanies: addEditCompaniesSlice,
  addEditCompanyDetails: addEditCompanyDetailsSlice,
  getSeagageLastSyncInfo: getSeagageLastSyncInfoSlice,
  preprocessingcontractdetails: preProcessingContractDetailsSlice,
  PreProcessingContractsList: getPreProcessingContractsListSlice,
  getModuleList: getModuleListSlice,
  getPersonaTitleList: getPersonaTitleListSlice,
  getPersonaData : getPersonaDataSlice,
  getGeneratedMessage : getGeneratedMessageDataSlice,
  movePreProcessedContracts: movePreProcessedContractsSlice,
  deleteScreeningTerms: deleteScreeningTermSlice,
  upsertGenerateMessage : upsertGeneratedMessageSlice,
  screeningContractsStatus: screeningContractsStatusListSlice,
  getMessageCreatorList: getMessageCreatorSlice,
  reanalyzeScreeningContract: reanalyzeScreeningContractSlice,
  isUsernameExist: isUsernameExistSlice,
  deleteMessage : deleteMessageSlice,
  deleteScreeningContracts: deleteScreeningContractsSlice,
  getSalesTrainingPromptList: getSalesTrainingPromptSlice,
  getUsersWithoutPermissions: getUsersWithoutPermissionsSlice,
  addSalesTrainingPrompt: addSalesTrainingPromptSlice,
})
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: persistedReducer,
});
export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
