import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { addSalesTrainingPromptEndPoint } from "../../networking/apiEndpoints";
import { SalesTrainingPromptRequestModel, SaveSalesTrainingPromptResponseState } from "../../types/components/rass/salesTrainingPromptModel";

const initialState: SaveSalesTrainingPromptResponseState = {
    addResponse: {
        status: false,
        message: '',
        result: null
    },
    loading: false,
    error: null,
    responseStatus: 200
};

// Add 
export const addSalesTrainingPrompt = createAsyncThunk<
AxiosResponse | any,
any,
{
    rejectValue: AxiosResponse;
}>(
    "addSalesTrainingPrompt/post",
    async (model: SalesTrainingPromptRequestModel, thunkApi) => {
        try {
            var token = localStorage.getItem('token');      
            const response = await axios.post(addSalesTrainingPromptEndPoint, model , {
                headers: { "Content-Type": "application/json", Authorization:`Bearer ${token}`}
            });
            if (response.status === 401) {
                return thunkApi.rejectWithValue(response);
            }
            return response.data;
        } catch (err) {
            console.log(err);
        }
    }
);
export const addSalesTrainingPromptSlice = createSlice({
    name: "addSalesTrainingPrompt",
    initialState,
    reducers:{} ,
    extraReducers: (builder) => {
        builder
            .addCase(addSalesTrainingPrompt.pending, (state, action) => {
                state.loading = true;
                state.error = '';
            })
            .addCase(addSalesTrainingPrompt.fulfilled, (state, action) => {
                state.addResponse = action.payload?.data;
                if(action.payload !== undefined)
                    {
                      state.responseStatus = action.payload.status;
                    }
                state.loading = false;
            })
            .addCase(addSalesTrainingPrompt.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message ?? '';
                if(action.payload !== undefined)
                    {
                      state.responseStatus = action.payload.status;
                    }
            });
    },
});
export default addSalesTrainingPromptSlice.reducer;