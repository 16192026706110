import React, { lazy } from "react";
import { RouteObject } from "react-router-dom";
import SalesMarketingDataSync from "../pages/rras/SalesMarketingDataSync";
import { REGPaths } from "../utilities/Constants";
import ViewMessage from "../components/rras/Message/viewMassage";
import { EditSalesTrainingPrompt } from "../components/rras/SalesTrainingPrompt/editSalesTrainingPrompt";

const SummaryTemplateList = lazy(
  () => import("../pages/rras/SummaryTemplate/Index")
);
const AddSummaryTemplate = lazy(() =>
  import("../components/rras/SummaryTemplate/addSummaryTemplate").then(
    (module) => ({ default: module.AddSummaryTemplate })
  )
);
const EditSummaryTemplate = lazy(() =>
  import("../components/rras/SummaryTemplate/editSummaryTemplate").then(
    (module) => ({ default: module.EditSummaryTemplate })
  )
);
const EmailTemplateListPage = lazy(() => import("../pages/rras/emailTemplate"));
const AddEmailTemplate = lazy(() =>
  import("../components/rras/EmailTemplate/addEmailTemplate").then(
    (module) => ({ default: module.AddEmailTemplate })
  )
);
const EditEmailTemplate = lazy(() =>
  import("../components/rras/EmailTemplate/editEmailTemplate").then(
    (module) => ({ default: module.EditEmailTemplate })
  )
);
const ViewEmail = lazy(
  () => import("../components/rras/CreateEmail/ViewEmail")
);
const CreateNewEmail = lazy(
  () => import("../components/rras/CreateEmail/CreateEmail")
);

const EmailCreatorList = lazy(() =>
  import("../pages/rras/EmailCreator/Index").then((module) => ({
    default: module.EmailCreatorList,
  }))
);
const MessageList = lazy(
  () => import("../pages/rras/Messages")
);
const AddMessage = lazy(
  () => import("../components/rras/Message/addMassage")
);

const SalesTrainingPromptList = lazy(
  () => import("../pages/rras/SalesTrainingPrompt/Index")
);
const AddSalesTrainingPrompt = lazy(() =>
  import("../components/rras/SalesTrainingPrompt/addSalesTrainingPrompt").then(
    (module) => ({ default: module.AddSalesTrainingPrompt })
  )
);

const rrasRoutes: RouteObject[] = [
  { path: REGPaths.pathMessages, element: <MessageList /> },
  { path: REGPaths.pathAddMessage, element: <AddMessage /> },  
  { path: `${REGPaths.pathViewMessage}/:id`, Component: () =><ViewMessage mode ="view"></ViewMessage> },
  { path: REGPaths.pathFollowUpEmailCreator, element: <EmailCreatorList /> },
  { path: REGPaths.pathSummaryTemplates, element: <SummaryTemplateList /> },
  { path: REGPaths.pathAddSummaryTemplate, element: <AddSummaryTemplate /> },
  { path: `${REGPaths.pathEditSummaryTemplate}/:id`, element: <EditSummaryTemplate /> },
  { path: REGPaths.pathEmailTemplates, element: <EmailTemplateListPage /> },
  { path: REGPaths.pathAddEmailTemplate, element: <AddEmailTemplate /> },
  { path: `${REGPaths.pathEditEmailTemplate}/:id`, element: <EditEmailTemplate /> },
  {
    path: `${REGPaths.pathViewEmail}/:id`,
    Component: () => <ViewEmail mode="view"></ViewEmail>,
  },
  {
    path: REGPaths.pathCreateEmail,
    Component: () => <CreateNewEmail mode="add"></CreateNewEmail>,
  },
  { path: REGPaths.pathSync, element: <SalesMarketingDataSync /> },
  { path: REGPaths.pathSalesTrainingPrompts, element: <SalesTrainingPromptList /> },
  { path: REGPaths.pathAddSalesTrainingPrompts, element: <AddSalesTrainingPrompt /> },
  { path: `${REGPaths.pathEditSalesTrainingPrompts}/:id`, element: <EditSalesTrainingPrompt /> },
];

export default rrasRoutes;
