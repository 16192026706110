import { createSlice,  createAsyncThunk } from "@reduxjs/toolkit";
import { DeleteSummaryTemplateType } from "../../../types/components/rass/deleteSummaryTemplateType";
import axios from "axios";
import { deleteSummaryTemplate } from "../../../networking/apiEndpoints";
import { DeleteSummaryTemplate } from "../../../types/components/rass/summaryTemplateModel";

const initialState: DeleteSummaryTemplateType = {
  isDeleted : false ,
  isLoading :false ,
  message : ""  
};
export const deleteSummaryTemplateById  = createAsyncThunk(
    "deletesummarytemplate/delete",
    async (summaryTemplate : DeleteSummaryTemplate) => {
        try {          
          var token = localStorage.getItem('token');     
          const { ids, tenantId } = summaryTemplate; 
          const requestBody = {
            tenantId: tenantId,
            ids: ids
          };       
          const response  = await axios.delete(deleteSummaryTemplate, {
          headers:{"Content-Type": "application/json", Authorization:`Bearer ${token}`},
          data:requestBody
        });    
                  
        return response.data;
      } catch (err) {
        console.log(err);
      }
    }
  );
  
  export const deleteSummaryTemplateSlice = createSlice({
    name: "deletesummarytemplate",
    initialState,
    reducers: {},
    extraReducers: (builder) => {  
      builder.addCase(deleteSummaryTemplateById.fulfilled, (state, action ) => {
        
        if(action.payload?.status === 200)
        { 
          state.isDeleted  = true;
          state.isLoading = false;        
        }
        else{
            state.isDeleted = false;
            state.isLoading = false;  
        }
      });
      builder.addCase(deleteSummaryTemplateById.pending, (state, action) => {
        state.isLoading = true;
    }) 
      },
  });
  
  export default deleteSummaryTemplateSlice.reducer;