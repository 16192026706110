import Button from "../../cst/Button";
import {Container, Grid, Paper, CircularProgress,} from "@mui/material";
import TextBox from "../../cst/TextField";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { ResponseWithSelectedModelState } from "../../../redux/features/getAllTenantSlice";
import Handle401Status from "../../../types/components/handleApiReasponseStatus";
import { addEmailTemplate } from "../../../redux/features/addEmailTemplateSlice";
import { 
  CancelDialogEmailTemplate, 
  CustomEmailTemplateType, 
  FailToSaveEmailTemplate, 
  RequiredFields, 
  SaveEmailTemplate, 
  REGPaths,
  MAX_TEXT_LENGTH,
  TEXT_FIELD_LABEL,
  EditSalesTrainingPromptHeader,
  ViewSalesTrainingPromptHeader,
  StandardSalesTrainingPromptHeader
} from "../../../utilities/Constants";
import DialogWithTwoButton from "../../cst/DialogWithTwoButton";
import CustomSnackbar from "../../cst/CustomSnackbar";
import { ValidationRule } from "../../../types/components/validationType";
import { SalesTrainingPromptRequestModel } from "../../../types/components/rass/salesTrainingPromptModel";
import { fetchSalesTrainingPromptList } from "../../../redux/features/rras/getSalesTrainingPromptSlice";

type FormErrors = {
  [key: string]: string;
};

export const EditSalesTrainingPrompt: React.FC<{ mode?: string }> = ({
  mode
}) => {
  const { id } = useParams();
  const allTenant: ResponseWithSelectedModelState = useAppSelector((state) => {
    return state.getAllTenant;
  });
  const { salesTrainingPromptResponseModel } = useAppSelector((state) => state.getSalesTrainingPromptList);
  const [values, setValues] = useState<SalesTrainingPromptRequestModel>({
    id: "",
    tenantId: allTenant.selectedTenant ?? "",
    name: "",
    text: "",
    type: "",
    orderNo : "",
    createdBy: "",
  });
  const [tittle, setTittle] = useState<string>('');
  const [viewMode, setViewMode] = useState(true)
  const [errors, setErrors] = useState<FormErrors>({});
  const [contractTypeErrorFlag, setContractTypeErrorFlag] = useState(false);
  const [isCancelEnabled, setIsCancelEnabled] = useState(false);
  const [isOpen, setisOpen] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);
  const [initialName, setInitialName] = useState<string>("");
  const [initialText, setInitialText] = useState<string>("");
  const [initialOrderNo, setInitialOrderNo] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [failToSave, setFailToSave] = useState<boolean>(false);
  const [saveSucessfully, setSaveSucessfully] = useState<boolean>(false);
  const [faledMessage, setFaledMessage] = useState<string>(FailToSaveEmailTemplate);

  const handleYesButtonClick = () => {
    navigate(REGPaths.pathSalesTrainingPrompts);
  };
  const handleNoButtonClick = () => {
    setisOpen(false);
  };  
  const handleDialogClose = () => {
    setisOpen(false);
  };
  const handleCloneButtonClick = () => {
    navigate(REGPaths.pathAddSalesTrainingPrompts, { state: { id } });
  };
  const Dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [result, setResult] = useState(salesTrainingPromptResponseModel);
  const [editCustomEmailTemplatePermissionNoAccess, setEditCustomEmailTemplatePermissionNoAccess] = useState<boolean>(true);
  useEffect(() => {
    setIsLoading(true);
    Dispatch(
      fetchSalesTrainingPromptList({
        id: id,
        tenantId: allTenant.selectedTenant,
      })
    ).then((getSalesTrainingPromptResponse) => {
      setResult(getSalesTrainingPromptResponse.payload);
      setIsLoading(false);
      if (getSalesTrainingPromptResponse.payload?.status === 401) {
        Handle401Status(navigate, window);
      }
    });
  }, [allTenant.selectedTenant]);

  useEffect(() => {
    setIsLoading(true);    
    Dispatch(
      fetchSalesTrainingPromptList({
        id: id,
        tenantId: allTenant.selectedTenant,
      })
    ).then((getSalesTrainingPromptResponse) => {
      setResult(getSalesTrainingPromptResponse.payload[0]);
      setIsLoading(false);
      const fetchedDataById = getSalesTrainingPromptResponse.payload[0];
      setValues((prevValues) => ({
        ...prevValues,
        id: fetchedDataById.id,
        name: fetchedDataById.name,
        text: fetchedDataById.text,
        orderNo: fetchedDataById.orderNo,
        type: fetchedDataById.type,
      }));
      if(fetchedDataById.type === CustomEmailTemplateType){
        setTittle(viewMode ? ViewSalesTrainingPromptHeader : EditSalesTrainingPromptHeader);
      } 
      else {
        setTittle(StandardSalesTrainingPromptHeader);
      }
      setInitialName(fetchedDataById.name);
      setInitialText(fetchedDataById.text);
      setInitialOrderNo(fetchedDataById.orderNo);

      if (getSalesTrainingPromptResponse.payload?.status === 401) {
        Handle401Status(navigate, window);
      }
    });    
  }, []);

  function EnableSaveButton(name, orderNo, text){
    setSaveDisabled(true);
    if(name !== initialName && text !== initialText && orderNo !== initialOrderNo){
      setSaveDisabled(false);
    }
  }
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setIsCancelEnabled(true);
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
    switch (name) {
      case "name":
        EnableSaveButton(value, values.orderNo, values.text);
        break;
    case "orderNo":
        EnableSaveButton(values.name, value, values.text );
        break;
    case "text":
        EnableSaveButton(values.name, values.orderNo, value);
        break;
      default:
        break;
    }
  };
  const validateForm = () => {
    const newErrors: FormErrors = {};
    Object.entries(values).forEach(([key, value]) => {
      const rules: ValidationRule = {
        required: true,
        maxLength: key === "name" ? 500 : 10000,
      };
      if (value === undefined) return;
      if (rules.required && value === "" && key === "name") {
        newErrors["name"] = RequiredFields;
      }
      if (rules.required && value.trim() === "0" && key === "orderNo") {
        newErrors["orderNo"] = RequiredFields;
      }
      if (rules.required && value === "" && key === "text") {
        newErrors["text"] = RequiredFields;
      }
      if (rules.maxLength && value.length > rules.maxLength && (key === "name" || key === "text")) {
        newErrors[key] = `Maximum length exceeded (${rules.maxLength} characters max)`;
      }  
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleCancel = () => {
    if (isCancelEnabled) {
      setisOpen(true);
    } else {
      navigate(REGPaths.pathSalesTrainingPrompts);
    }
  };
  const handleEdit = async () => {
    setViewMode(false);
    setTittle(EditSalesTrainingPromptHeader);
  }
  const handleSubmit = async () => {
    if (!validateForm()) return;
    setIsLoading(true);
    Dispatch(addEmailTemplate(values)).then((response) => {
      setIsLoading(false);
      if (response.payload?.status === 401) {
        Handle401Status(navigate, window);
      }
      if (!response.payload) {
        setFailToSave(true);
      } else {
        if (response.payload?.status === false){
          setFailToSave(true);
          setFaledMessage(response.payload?.message);
          return;
        }
        setSaveSucessfully(true);
        setTimeout(() => {
          navigate(REGPaths.pathEmailTemplates);
        }, 600);
      }
    });
  };
  return (
    <>
      <div className="container contractTypeContainer">
        <div className="page-title">
          <h1>
            {tittle}
          </h1>
        </div>
        <Paper className="add-type-container main-paper" sx={{ pt: 2, pb: 2 }}>
          <div className="">
            <Container maxWidth="xl">
              {isLoading && (
                <div className="loader-center">
                  <CircularProgress />
                </div>
              )}
              {!isLoading && <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextBox
                    name="name"
                    label="Prompt Name *"
                    variant="outlined"
                    size="small"
                    maxLength={150}
                    value={values.name}
                    onChange={handleChange}
                    error={!!errors.name}
                    helperText={errors.name}
                    className={
                      errors.name
                        ? "errorField rounded-input"
                        : "rounded-input"
                    }
                    disabled={viewMode}
                />
                </Grid>
                <Grid item xs={5}>
                <TextBox
                    name="orderNo"
                    label="Order Id *"
                    variant="outlined"
                    size="small"
                    maxLength={3}
                    value={values.orderNo}
                    onChange={handleChange}
                    error={!!errors.orderNo}
                    helperText={errors.orderNo}
                    className={
                      errors.orderNo
                        ? "errorField rounded-input"
                        : "rounded-input"
                    }
                    disabled={viewMode}
                />
                </Grid>
                <Grid item xs={12}>
                <TextBox
                    name="text"
                    label="Prompt Text *"
                    variant="outlined"
                    size="small"
                    multiline
                    rows={4}
                    maxLength={MAX_TEXT_LENGTH}
                    value={values.text}
                    onChange={handleChange}
                    error={!!errors.text}
                    helperText={errors.text}
                    className={
                      errors.text
                        ? "errorField rounded-input"
                        : "rounded-input"
                    }
                    disabled={viewMode}
                />
                </Grid>
                <Grid item xs={12} className="form-buttons">
                  <Button
                    onClick={handleCancel}
                    className="commoncancelbutton mr-20px no-bg disabled"
                    type="contained"
                    text="Cancel"
                    startIcon={''}
                  ></Button>
                  {values.type == 'Standard' &&
                  <Button
                    className="common-disablebutton clone"
                    onClick={handleCloneButtonClick}
                    type="contained"
                    text="Clone"
                    startIcon={''}         
                    disabled={editCustomEmailTemplatePermissionNoAccess}           
                  ></Button>
                  }
                  {viewMode && values.type == 'Custom' &&
                  <Button 
                    className="common-disablebutton"
                    type="contained"
                    text="Edit"
                    startIcon={''}
                    onClick={handleEdit}  
                    disabled={editCustomEmailTemplatePermissionNoAccess}                  
                    ></Button>
                  }
                  {!viewMode &&
                  <Button 
                    className="common-disablebutton"
                    type="contained"
                    text="Save"
                    startIcon={''}
                    disabled={saveDisabled}
                    onClick={handleSubmit}
                    ></Button>
                  }
                  {failToSave && (
                    <CustomSnackbar
                      message={faledMessage}
                      severity="error"
                    />
                  )}
                  {saveSucessfully && (
                    <CustomSnackbar
                      message={SaveEmailTemplate}
                      severity="success"
                    />
                  )}
                </Grid>
              </Grid>}
            </Container>
          </div>
        </Paper>
        <DialogWithTwoButton
          dialogOpen={isOpen}
          message={CancelDialogEmailTemplate}
          noBtnClick={handleNoButtonClick}
          yesBtnClick={handleYesButtonClick}
          dialogClose={handleDialogClose}
        />
      </div>
    </>
  );
};

